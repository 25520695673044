import axios from "axios";

import * as api from "../constants/api";
import { LearningObject } from "../models/LearningObject";
import { getUserData } from "../utils/getUserData";
import { isBtnSpinning } from "../utils/isBtnSpinning";
import { startBtnSpin } from "../utils/startBtnSpin";
import { stopBtnSpin } from "../utils/stopBtnSpin";

import { fetchFavoriteStatus } from "./fetchFavoriteStatus";

export async function toggleFavoriteStatus({
  btnElem,
  productId,
  learningObject,
}: {
  btnElem: HTMLElement;
  productId: string;
  learningObject: LearningObject;
}): Promise<boolean> {
  // bail out if we're already toggling favorite status on the button
  if (isBtnSpinning(btnElem)) {
    return false;
  }

  // ... otherwise begin showing a throbber right away
  startBtnSpin(btnElem);

  try {
    const isFavorite = await fetchFavoriteStatus(learningObject.id);

    if (!isFavorite) {
      try {
        await addFavoriteStatus(productId, learningObject);
      } catch (err) {
        alert(
          `Der opstod en fejl, da vi forsøgte at tilføje "${learningObject.title}" til dine favoritter.`
        );

        throw err;
      }
    } else {
      try {
        await removeFavoriteStatus(learningObject.id);
      } catch (err) {
        alert(
          `Der opstod en fejl, da vi forsøgte at fjerne "${learningObject.title}" fra dine favoritter.`
        );

        throw err;
      }
    }

    btnElem.classList.toggle("favorite", !isFavorite);
  } catch (err) {
    console.error(err);
    return false;
  } finally {
    stopBtnSpin(btnElem);
  }

  return true;
}

async function addFavoriteStatus(
  productId: string,
  learningObject: LearningObject
): Promise<void> {
  const userData = getUserData();

  await axios.post(
    api.addFavorite,
    JSON.stringify({
      userID: userData.userId,
      userInstitutionID: userData.instId,
      productID: productId,
      learningObject,
    }),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

async function removeFavoriteStatus(learningObjectId: string): Promise<void> {
  await axios.delete(
    api.removeFavorite
      .replace("%userId", getUserData().userId)
      .replace("%learningObjectId%", learningObjectId)
  );
}
