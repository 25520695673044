import { fetchComponentEntryById } from "./api/fetchComponentEntryById";
import { fetchFavoriteStatus } from "./api/fetchFavoriteStatus";
import { fetchTaskResultById } from "./api/fetchTaskResultById";
import { toggleFavoriteStatus } from "./api/toggleFavoriteStatus";
import * as api from "./constants/api";
import * as userAgent from "./constants/userAgent";
import {
  ComponentContent,
  ComponentEntryContent,
  Content,
  IframeContent,
  ImageContent,
  TaskResultContent,
} from "./models/Content";
import { LearningObject } from "./models/LearningObject";
import { Options } from "./models/Options";
import * as styles from "./styles";
import { getUserData } from "./utils/getUserData";
import { isEntry } from "./utils/isEntry";
import { openNotebook } from "./utils/openNotebook";
import { stopBtnSpin } from "./utils/stopBtnSpin";

declare global {
  interface Window {
    jQuery?: JQueryStatic;
    gLightbox?: typeof gLightbox;
  }
}

const $ = window.jQuery;

const IS_TOUCH_OS =
  userAgent.windowsPhone ||
  userAgent.androidMobile ||
  userAgent.androidTablet ||
  userAgent.iPhone ||
  userAgent.iPad;

let isOpen = false;

/**
 * Creates a lightbox that overlaps the entire visible page, giving the user the
 * impression that he's now fully entered the contents embedded in the lightbox.
 */
class gLightbox {
  /** Helper that specifies if a lightbox is currently open */
  public static isOpen() {
    return !!isOpen;
  }

  /** Caches the original scroll-settings when the lightbox was opened */
  private previousOverflowStyle: string;

  /** Specifies if the lightbox has been closed or not */
  private isClosed = false;

  /** Contains the index of the currently shown button */
  private currentIndex: number;

  /** Contains the options given when the lightbox was created */
  private readonly options: Options[];

  /** Contains a reference to the dom-node that wraps the entire lightbox */
  private readonly wrapper: HTMLElement;

  /** Contains references to containers previously rendered in the lightbox */
  private readonly containers = new Map<number, HTMLElement>();

  /** Contains cached references to all bound event-listenres */
  private readonly clickListeners = new Set<{
    elem: Element;
    listener: EventListener;
  }>();

  /** Indicates if we're currently navigating to another task */
  private transitioning = false;

  /**
   * Cache options and render the lightbox when a new instance is created.
   */
  constructor(options: Options | Options[], index: number) {
    if (isOpen) {
      throw new Error("new gLightbox(...): Another lightbox is already open!");
    }

    isOpen = true;

    // extract original overflow settings for the parent window, so that we can
    // properly restore the value later on
    this.previousOverflowStyle = document.documentElement.style.getPropertyValue(
      "overflow"
    );

    document.documentElement.style.overflow = "hidden";

    // convert single item configuration into an array, so the rest of the
    // integration can safely assume a single, consistent syntax
    this.options = Array.isArray(options) ? options : [options];
    this.currentIndex = index || 0;

    // Create a wrapper-component that can contain the contents of the
    // lightbox
    this.wrapper = document.createElement("div");
    this.wrapper.setAttribute("data-g-lightbox", "true");
    this.wrapper.className = `${styles.lightboxCls} interface-${
      IS_TOUCH_OS ? "touch" : "mouse"
    }`;

    this.wrapper.appendChild(this.renderContainer(this.currentIndex));
    document.body.appendChild(this.wrapper);

    // bind key listeners
    window.addEventListener("keyup", this.onKeyPress, true);
  }

  /**
   * Render the contents of an embedded element in the lightbox (this is
   * essentially the templating of the lightbox).
   */
  renderContainer(index: number): HTMLElement {
    const cachedElement = this.containers.get(index);

    if (cachedElement) {
      return cachedElement;
    }

    // Fetch options for rendering the page
    const options = this.options[Number(index)];

    const container = document.createElement("div");
    container.className = styles.wrapperCls;
    container.innerHTML = `
      <header class="${styles.headerCls}">
        <h1 class="${styles.headerTitleCls}">${options.title}</h1>

        <a data-btn="notebook" class="${styles.headerButtonCls}">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" height="13" fill="currentColor">
            <path d="M34.328 67.93v887.416c0 37.578 31.076 67.93 67.93 67.93h67.93V0h-67.93c-37.578 0-67.93 30.352-67.93 67.93zM921.746 0H238.838v1024h750.836V0h-67.93zM409.384 795.64l-97.558 13.73 27.462-195.116 167.654 167.654-97.558 13.73zm125.02-42.636L367.472 586.072 645.694 307.85l138.75 138.75 28.176 27.462-278.216 278.946zm366.384-361.326c-1.446 1.446-2.89 1.446-4.336 2.892l.722.722-53.476 53.476-166.932-166.932 53.476-53.478.722.722c.722-1.446 1.446-2.89 2.89-4.336 14.452-14.454 39.016-14.454 54.198 1.446l111.28 111.29c15.182 15.176 15.904 39.746 1.452 54.2z"/>
          </svg>
        </a>
        <a data-btn="favorite" class="${styles.headerButtonCls} throbber spin" style="display: none;">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1076 1024" height="13" fill="currentColor">
            <path d="M537.828 0l133.697 382.861 404.89 8.356-322.849 245.365L870.551 1024 537.827 792.309 205.103 1024l117.745-387.418L-.001 391.217l404.89-8.356z"/>
          </svg>
        </a>
        <a data-btn="close" class="${styles.headerButtonCls}">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" height="13" fill="currentColor">
            <path d="M82.441 1024L0 941.559 941.559 0 1024 82.441z"/>
            <path d="M941.559 1024L1024 941.559 82.441 0 0 82.441z"/>
          </svg>
        </a>
      </header>

      <section class="${styles.containerCls}"></section>
    `;

    const btnClose = container.querySelector(`[data-btn="close"]`);
    const contents = container.querySelector<HTMLDivElement>(
      `.${styles.containerCls}`
    );

    this.initTitleElement(container, options);
    this.initNotebookBtn(container, options);
    this.initFavoriteBtn(container, options);

    // Bind function to handle closing the lightbox
    this.attachClickListener(btnClose, () => this.close());

    // Render the contents inside the lightbox
    switch (options.content.type) {
      case "iframe":
      case "component":
      case "componentEntry":
      case "taskResult":
        if (contents) {
          const iframe = this.renderIframe(contents, options);

          // Inject into the DOM after transitions finish
          setTimeout(() => {
            contents?.appendChild(iframe);
          }, 625);
        }
        break;

      case "image":
        if (contents) {
          contents.appendChild(this.renderImage(contents, options.content));
        }
        break;

      default:
        const content = options.content as Content;

        console.error(
          `gLightbox.renderContainer(...): ` +
            `The type "${content.type}" is not supported.`
        );
        break;
    }

    // cache container for future usage
    this.containers.set(index, container);

    return container;
  }

  private initTitleElement(container: HTMLElement, options: Options): void {
    const titleElem = container.querySelector<HTMLElement>(
      `.${styles.headerTitleCls}`
    );

    if (!titleElem) {
      return;
    }

    // ... otherwise simply inject the desired title
    if (options.title) {
      titleElem.innerText = options.title;
    } else {
      titleElem.remove();
    }
  }

  private initNotebookBtn(container: HTMLElement, options: Options): void {
    const btnElem = container.querySelector(`[data-btn="notebook"]`);

    if (!btnElem) {
      return;
    }

    // hide the notebook button for anything but component entries, which the
    // user has created himself
    if (
      options.disableNotebookButton ||
      !isEntry(options.content) ||
      !options.productId ||
      options.content.userId !== getUserData().userId
    ) {
      btnElem.remove();
      return;
    }

    const $btnElem = $?.(btnElem);

    if ($btnElem?.gPanels) {
      $btnElem.gPanels({
        type: "notes",
        iconType: "none",
        productId: options.productId,
        userInstitutionId: getUserData().instId,
        context: {
          learningObject: options.learningObject,
        },
      });
    } else {
      this.attachClickListener(btnElem, () => openNotebook(options));
    }
  }

  private initFavoriteBtn(container: HTMLElement, options: Options): void {
    const btnElem = container.querySelector<HTMLElement>(
      `[data-btn="favorite"]`
    );

    if (!btnElem) {
      return;
    }

    if (!options.productId || !options.learningObject) {
      btnElem.remove();
      return;
    }

    const productId = options.productId;
    const learningObject = options.learningObject;

    fetchFavoriteStatus(options.learningObject.id).then((isFavorite) => {
      stopBtnSpin(btnElem);

      // Bind an event-listener to toggle status on/off
      this.attachClickListener(btnElem, () =>
        toggleFavoriteStatus({
          btnElem,
          productId,
          learningObject,
        })
      );

      if (isFavorite) {
        btnElem.classList.add("favorite");
      }
    });
  }

  private renderIframe(
    container: HTMLElement,
    options: Options
  ): HTMLIFrameElement {
    // Create an iframe that contain the desired contents
    const iframe = document.createElement("iframe");

    // Configure iFrame
    iframe.setAttribute("allowFullscreen", "true");
    iframe.setAttribute("allowTransparency", "true");
    iframe.setAttribute("frameborder", "0");

    // Apply CSS
    iframe.className = `${styles.contentCls} type--iframe`;

    switch (options.content.type) {
      case "iframe":
        this.initRawIframe(container, iframe, options.content);
        break;

      case "component":
        this.initComponentIframe(
          container,
          iframe,
          options.content,
          options.learningObject
        );
        break;

      case "componentEntry":
        this.initComponentEntryIframe(
          container,
          iframe,
          options.content,
          options.learningObject
        );
        break;

      case "taskResult":
        this.initTaskResultIframe(container, iframe, options.content);
        break;
    }

    return iframe;
  }

  private initRawIframe(
    container: HTMLElement,
    iframe: HTMLIFrameElement,
    content: IframeContent
  ): void {
    // Wait for the iframe to load before being rendered
    iframe.onload = () => {
      container.classList.add("loaded");
      iframe.onload = null;
    };

    // Source must be given as an absolute string
    if (typeof content.src === "string") {
      iframe.src = content.src as string;
    } else {
      throw new TypeError(
        'gLightbox.renderContainer(...): content.src must be given as a string when content.type is "iframe"!'
      );
    }
  }

  private initComponentIframe(
    container: HTMLElement,
    iframe: HTMLIFrameElement,
    content: ComponentContent,
    learningObject: LearningObject | undefined
  ): void {
    const userData = getUserData();

    // Prepare the URL that we need to forward the user to
    let url = `${api.componentProxy}/ComponentViewer`;

    // Add authentication info to the URL
    url += "?userID=" + encodeURIComponent(userData.userId);
    url += "&componentID=" + encodeURIComponent(content.componentId);
    url += "&componentType=" + encodeURIComponent(content.componentType);

    if (userData.instId) {
      url += "&institutionID=" + encodeURIComponent(userData.instId);
    }

    if (learningObject) {
      url += "&learningObjectId=" + encodeURIComponent(learningObject.id);
      url += "&learningObjectTitle=" + encodeURIComponent(learningObject.title);
    }

    // Wait for the iframe to load before being rendered
    iframe.onload = () => {
      container.classList.add("loaded");
      iframe.onload = null;
    };

    // Open the component in the iframe
    iframe.src = window.gProxy.forwardTo(url);
  }

  private initComponentEntryIframe(
    container: HTMLElement,
    iframe: HTMLIFrameElement,
    content: ComponentEntryContent,
    learningObject: LearningObject | undefined
  ): void {
    fetchComponentEntryById(content.entryId)
      .then((entry) => {
        const userData = getUserData();

        // Prepare the URL that we need to forward the user to
        let url =
          userData.userId === entry.userID
            ? `${api.componentProxy}/ComponentViewer`
            : `${api.componentProxy}/ComponentEntry`;

        // Add authentication info to the URL
        url += "?userID=" + encodeURIComponent(userData.userId);
        url += "&componentID=" + encodeURIComponent(entry.componentID);
        url += "&componentEntryID=" + encodeURIComponent(entry.objectID);
        url += "&componentType=" + encodeURIComponent(entry.componentType);

        if (userData.instId) {
          url += "&institutionID=" + encodeURIComponent(userData.instId);
        }

        if (learningObject) {
          url += "&learningObjectId=" + encodeURIComponent(learningObject.id);
          url +=
            "&learningObjectTitle=" + encodeURIComponent(learningObject.title);
        }

        // Wait for the iframe to load before being rendered
        iframe.onload = () => {
          container.classList.add("loaded");
          iframe.onload = null;
        };

        // Open the component in the iframe
        iframe.src = window.gProxy.forwardTo(url);
      })
      .catch((err) => {
        console.error(err);

        // Show message if data about the entry couldn't be fetched
        this.showMessage(
          container,
          "Der opstod en fejl under viderestilling, vi beklager..."
        );

        // Kill the iframe
        iframe.style.display = "none";
      });
  }

  private initTaskResultIframe(
    container: HTMLElement,
    iframe: HTMLIFrameElement,
    content: TaskResultContent
  ): void {
    fetchTaskResultById(content.entryId)
      .then((entry) => {
        // Prepare the URL that we need to forward the user to
        let url = process.env.TASK_PLAYER_URL ?? "";

        // Now add authentication to the request
        url += "?taskPlayerId=" + encodeURIComponent(entry.taskPlayerID);
        url += "&userName=" + encodeURIComponent(getUserData().userId);
        url += "&productID=" + encodeURIComponent(entry.productID);
        url += "&title=" + encodeURIComponent(entry.title);
        url += "&resultID=" + encodeURIComponent(entry.objectID);

        // Wait for the iframe to load before being rendered
        iframe.onload = () => {
          container.classList.add("loaded");
          iframe.onload = null;
        };

        // Open the taskplayer in the popup-window
        iframe.src = url;
      })
      .catch(() => {
        // Show message if data about the entry couldn't be fetched
        this.showMessage(
          container,
          "Der opstod en fejl under viderestilling, vi beklager..."
        );

        // Kill the iframe
        iframe.style.display = "none";
      });
  }

  private renderImage(
    container: HTMLElement,
    content: ImageContent
  ): HTMLDivElement {
    if (!window.gImageZoom) {
      return this.renderRawImage(container, content);
    } else {
      return this.renderImageZoom(container, content);
    }
  }

  private renderRawImage(
    container: HTMLElement,
    content: ImageContent
  ): HTMLDivElement {
    const startedAt = 625;

    const wrapper = document.createElement("div");
    wrapper.className = `${styles.contentCls} type--image`;

    const img = new Image();

    // Wait for the image to load before being rendered
    img.onload = img.onerror = img.onabort = () => {
      setTimeout(() => {
        container.classList.add("loaded");
      }, Math.max(0, 625 - (Date.now() - startedAt)));

      img.onload = img.onerror = img.onabort = null;
    };

    // Apply CSS
    img.className = styles.contentsImageCls;

    // Source must be given as an absolute string
    if (typeof content.src === "string") {
      img.src = content.src as string;
    } else {
      throw new TypeError(
        'gLightbox.renderContainer(...): content.src must be given as a string when content.type is "image" and gImageZoom is not available!'
      );
    }

    // Inject into the DOM
    wrapper.appendChild(img);

    return wrapper;
  }

  private renderImageZoom(
    container: HTMLElement,
    content: ImageContent
  ): HTMLDivElement {
    const wrapper = document.createElement("div");
    wrapper.className = `${styles.contentCls} type--image`;

    // Initialize the gImageZoom-component!
    setTimeout(() => {
      container.classList.add("loaded");

      setTimeout(() => {
        window.gImageZoom?.({
          node: wrapper,
          src: content.src,
          text: content.caption,
        });
      });
    }, 625);

    return wrapper;
  }

  /** Helper-method that applies the given listener to the click-event */
  private attachClickListener(
    elem: Element | null,
    listener: EventListener
  ): void {
    if (!elem) {
      return;
    }

    elem.addEventListener(IS_TOUCH_OS ? "touchend" : "click", listener, false);

    this.clickListeners.add({ elem, listener });
  }

  /**
   * Renders a message inside the container, which is useful if an error
   * occurs while trying to download the contents.
   */
  showMessage(container: Element, message: string): void {
    // Create DOM-elements
    const wrapper = document.createElement("div");
    const label = document.createElement("span");

    wrapper.appendChild(label);

    // Apply styling
    wrapper.className = `${styles.contentCls} type--message`;
    label.className = styles.contentsMessageLabelCls;

    // Inject message
    label.innerHTML = message;

    // Inject into the wrapper
    container.appendChild(wrapper);

    // Make sure that the message is displayed
    container.classList.add("loaded");
  }

  /**
   * Navigates to the specified index of available content pages in the
   * lightbox.
   */
  goTo(index: number): void {
    index = Math.max(0, Math.min(this.options.length - 1, index));

    // Skip if we're already at this index
    if (this.currentIndex === index || this.transitioning) {
      return;
    }

    this.transitioning = true;

    // Prepare leave for the currently rendered page
    const oldContainer = this.containers.get(this.currentIndex);
    const newContainer = this.renderContainer(index);

    // Reset current animations
    oldContainer?.classList.remove("transition--in-left");
    oldContainer?.classList.remove("transition--in-right");
    oldContainer?.classList.remove("transition--out-left");
    oldContainer?.classList.remove("transition--out-right");

    newContainer.classList.remove("transition--in-left");
    newContainer.classList.remove("transition--in-right");
    newContainer.classList.remove("transition--out-left");
    newContainer.classList.remove("transition--out-right");

    // Slide left or right?
    if (index > this.currentIndex) {
      oldContainer?.classList.add("transition--out-left");
      newContainer.classList.add("transition--in-left");
    } else {
      oldContainer?.classList.add("transition--out-right");
      newContainer.classList.add("transition--in-right");
    }

    // If rendering an iframe, then remove it temporarily while we're
    // transitioning to prevent framerate-drop!
    const newContents = newContainer.querySelector(`.${styles.containerCls}`);
    const embeddedIframe = newContainer.querySelector(
      `.${styles.containerCls} > iframe`
    );

    if (embeddedIframe) {
      newContents?.classList.remove("loaded");
      embeddedIframe?.remove();
    }

    // embed the next slide so we can run the transition
    this.wrapper.appendChild(newContainer);

    setTimeout(() => {
      // once the transition has been completed, remove the old container
      // entirely from the DOM to free up memory
      oldContainer?.remove();

      // and now re-inject the iframe into the lightbox, and wait for it to
      // load completely
      if (embeddedIframe) {
        const handleIframeLoad = () => {
          // render the iframe once it's loaded
          newContents?.classList.add("loaded");
          newContainer?.appendChild(embeddedIframe);

          embeddedIframe.removeEventListener("load", handleIframeLoad);
        };

        embeddedIframe.addEventListener("load", handleIframeLoad);
      }

      // Register that transitioning has finished
      this.transitioning = false;
    }, 625);

    // Update current index
    this.currentIndex = index;
  }

  /** Auto-close the lightbox if the user clicks escape */
  onKeyPress = (evt: KeyboardEvent): void => {
    evt.stopPropagation?.();
    evt.cancelBubble = true;

    switch (evt.key) {
      case "Escape":
        this.close();
        break;

      case "ArrowRight":
        this.goTo(this.currentIndex + 1);
        break;

      case "ArrowLeft":
        this.goTo(this.currentIndex - 1);
        break;
    }
  };

  /** Closes the lightbox and completely cleans up memory after it */
  close(): void {
    if (this.isClosed) {
      return;
    }

    this.isClosed = true;

    // Run the close-animation
    this.wrapper.classList.add("closing");

    // Unbind all associated event-listeners
    this.clickListeners.forEach((click) => {
      click.elem.removeEventListener(
        IS_TOUCH_OS ? "touchend" : "click",
        click.listener,
        false
      );
    });

    // Wait for the animation to finish, then remove the lightbox from the
    // DOM entirely
    setTimeout(() => {
      this.wrapper.remove();

      // Re-allow scrolling as it was configured prior to the user opening the
      // lightbox
      document.documentElement.style.overflow = this.previousOverflowStyle;

      isOpen = false;
    }, 625);

    window.removeEventListener("keyup", this.onKeyPress, true);
  }
}

window.gLightbox = gLightbox;
