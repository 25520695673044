import {
  ComponentEntryContent,
  Content,
  TaskResultContent,
} from "../models/Content";

export function isEntry(
  content: Content
): content is ComponentEntryContent | TaskResultContent {
  return content.type === "componentEntry" || content.type === "taskResult";
}
