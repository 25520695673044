export const ekeyUser = `${process.env.GAS_URL?.replaceAll(
  "{{version}}",
  "v"
)}/api/User/GetEkeyUserDetailsByEmail?userEmail=%userId%`;
export const unicUser = `${process.env.GAS_URL?.replaceAll(
  "{{version}}",
  "v1"
)}/api/v1/UnicUser/GetPerson?userId=%userId%`;
export const checkFavorite = `${process.env.USERDATA_V2_URL}/api/Favorites?userID=%userId%&learningObjectID=%learningObjectId%`;
export const addFavorite = `${process.env.USERDATA_V2_URL}/api/Favorites`;
export const removeFavorite = `${process.env.USERDATA_V2_URL}/api/Favorites?userID=%userId%&learningObjectID=%learningObjectId%`;
export const notebook = `${process.env.COMPONENT_SERVER_URL}/notesbog/?userID=%userId%&institutionID=%institutionId%&productID=%productId%`;
export const componentEntry = `${process.env.USERDATA_V2_URL}/api/ComponentEntries/%entryId%`;
export const componentProxy = `${process.env.COMPONENT_FRAMEWORK_URL}/Proxy`;
export const taskResult = `${process.env.USERDATA_V2_URL}/api/TaskResults/%entryId%`;
