const userAgent = navigator.userAgent.toLowerCase();

export const windowsPhone = userAgent.indexOf("windows phone") > -1;
export const androidMobile =
  userAgent.indexOf("android") > -1 &&
  userAgent.indexOf("mobile") > -1 &&
  !windowsPhone;
export const androidTablet =
  userAgent.indexOf("android") > -1 &&
  userAgent.indexOf("mobile") === -1 &&
  !windowsPhone;
export const iPhone =
  (userAgent.indexOf("iphone") > -1 || userAgent.indexOf("ipod") > -1) &&
  !windowsPhone;
export const iPad = userAgent.indexOf("ipad") > -1 && !windowsPhone;
