import axios, { AxiosError } from "axios";

import * as api from "../constants/api";
import { getUserData } from "../utils/getUserData";

export const FAVORITE_CACHE = new Map<string, boolean>();

/**
 * Helper-method that determines if a learning-object is currently marked
 * as a favorite by the user.
 */
export async function fetchFavoriteStatus(
  learningObjectId: string
): Promise<boolean> {
  if (FAVORITE_CACHE.has(learningObjectId)) {
    return !!FAVORITE_CACHE.get(learningObjectId);
  }

  const isFavorite = await forceFetchFavoriteStatus(learningObjectId);

  FAVORITE_CACHE.set(learningObjectId, isFavorite);

  return isFavorite;
}

async function forceFetchFavoriteStatus(
  learningObjectId: string
): Promise<boolean> {
  try {
    const response = await axios.get(
      api.checkFavorite
        .replace("%userId%", getUserData().userId)
        .replace("%learningObjectId%", learningObjectId)
    );

    return response.status !== 204;
  } catch (err) {
    if (isAxiosError(err) && err.response?.status === 404) {
      return false;
    }

    throw err;
  }
}

function isAxiosError(err: unknown): err is AxiosError {
  return !!(err && (err as Partial<AxiosError>).isAxiosError);
}
