import { css } from "linaria";

const MOBILE_BREAKPOINT = 767;

export const lightboxCls = css`
  --glightbox--font: "franklin-gothic-urw", "droid-sans", helvetica, arial,
    sans-serif;

  --glightbox--ease--in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
  --glightbox--ease--in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);
  --glightbox--ease--in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);

  --glightbox--color--dark-grey: 56, 56, 56;
  --glightbox--color--primary: 220, 67, 32;
  --glightbox--color--yellow: 255, 178, 53;

  /**
   * Position the lightbox on top of any other layer, making sure that it
   * stretches across the entire viewport
   */
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1999999;

  /**
   * Apply solid background-color to the lightbox
   */
  background: #fff;

  /**
   * Run animation when first displaying the lightbox
   */
  animation: open var(--glightbox--ease--in-out-quint) 625ms;

  /* Run custom close-animation */
  &.closing {
    animation: close var(--glightbox--ease--in-out-quint) 625ms;
    animation-fill-mode: forwards;
  }

  @keyframes open {
    0% {
      transform: scale3d(1.1, 1.1, 1.1);
      opacity: 0;
    }

    100% {
      transform: scale3d(1, 1, 1);
      opacity: 1;
    }
  }

  @keyframes close {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
`;

export const wrapperCls = css`
  /* Take over the entire available area of the lightbox */
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  backface-visibility: hidden;

  &.transition--in-left {
    animation: in-left var(--glightbox--ease--in-out-quart) 625ms;
  }

  &.transition--in-right {
    animation: in-right var(--glightbox--ease--in-out-quart) 625ms;
  }

  &.transition--out-left {
    animation: out-left var(--glightbox--ease--in-out-quart) 625ms;
    animation-fill-mode: forwards;
  }

  &.transition--out-right {
    animation: out-right var(--glightbox--ease--in-out-quart) 625ms;
    animation-fill-mode: forwards;
  }

  @keyframes in-left {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes out-left {
    0% {
      opacity: 1;
      transform: translateX(0);
    }

    100% {
      opacity: 0;
      transform: translateX(-100%);
    }
  }

  @keyframes in-right {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes out-right {
    0% {
      opacity: 1;
      transform: translateX(0);
    }

    100% {
      opacity: 0;
      transform: translateX(100%);
    }
  }
`;

export const headerCls = css`
  /* Stretch across the entire top of the lightbox */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;

  /* Setup sizing */
  height: 45px;

  /* Pull icons right */
  text-align: right;
  font: 0/0 serif;

  /* Apply styling */
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.15) 0 0 20px;

  /* Disable selection */
  user-select: none;
`;

export const headerTitleCls = css`
  float: left;

  /* Reset margins */
  margin: 0 0 0 14px;
  padding: 0;

  /* Setup font-styling */
  font: 500 17px/45px var(--glightbox--font);

  /* Prevent text-overflow */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  max-width: calc(100vw - 200px);

  /* Customize max-size depending on state */
  .withNav & {
    max-width: calc(50vw - 125px);
  }

  /* Title is not available on mobile phones */
  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;

export const headerNavCls = css`
  /* Allow the navigation to become centered */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  /* Center elements */
  text-align: center;

  /* Setup font-styling */
  font: 500 17px/45px var(--glightbox--font);

  /* Navigation is not available on mobile devices */
  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;

export const headerNavChevronCls = css`
  /* Center vertically */
  vertical-align: top;

  /* Apply margin */
  display: inline-grid;
  place-items: center;
  padding: 5px;
  margin: 0 25px;
  height: 33px;

  /* Prepare hover */
  transition: color linear 125ms;
  color: rgb(var(--glightbox--color--dark-grey));

  .interface-mouse &:hover,
  .interface-touch &:active {
    color: rgb(var(--glightbox--color--primary));
  }

  /* Indicate clickable element */
  cursor: pointer;

  /* Disabled startedAt */
  &.disabled {
    opacity: 0.4;

    .interface-mouse &:hover,
    .interface-touch &:active {
      color: rgb(var(--glightbox--color--dark-grey));
    }

    cursor: default;
  }

  /* Rotate the "prev"-arrow */
  &.prev > svg {
    transform: rotate(180deg);
  }
`;

export const headerButtonCls = css`
  /* Allow using position: absolute to render the border */
  position: relative;

  /* Setup sizing */
  display: inline-grid;
  width: 31px;
  height: 31px;
  place-items: center;

  /* Apply margins */
  margin-top: 7px;
  margin-right: 10px;

  /* Apply border */
  &::after {
    /* Overlap the entire button */
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    /* Setup custom transform origin */
    transform-origin: 15.5px 15.5px;

    /* Render border */
    border: 2px solid rgb(var(--glightbox--color--dark-grey));
    border-radius: 50%;

    /* Prepare throbber on/off toggling */
    transition: border-color linear 125ms;

    /* Force the pseudo-element to be rendered */
    content: "";
  }

  /* Do spin */
  &.spin::after {
    animation: spin linear 900ms infinite;
  }

  /* Prepare fade-out of icons */
  &::before {
    transition: transform var(--glightbox--ease--in-out-cubic) 275ms,
      opacity var(--glightbox--ease--in-out-cubic) 275ms;
  }

  /* Apply icon-styling */
  color: rgb(var(--glightbox--color--dark-grey));

  /* Prepare hover-transition */
  transition: color linear 125ms;

  /* Indicate that elements are clickable */
  cursor: pointer;

  /* Handle hover-transition */
  .interface-mouse &:hover,
  .interface-touch &:active {
    &::after {
      border-color: rgb(var(--glightbox--color--primary));
    }

    color: rgb(var(--glightbox--color--primary));
  }

  /* Show spinner when enabled */
  &.throbber {
    &::after {
      border-top-color: rgba(var(--glightbox--color--dark-grey), 0.25);
    }

    .interface-mouse &:hover,
    .interface-touch &:active {
      &::after {
        border-top-color: rgba(var(--glightbox--color--primary), 0.25);
      }
    }
  }

  /* Customize icon states */
  &.favorite {
    color: rgb(var(--glightbox--color--yellow));

    &::after {
      border-color: rgb(var(--glightbox--color--yellow));
    }

    &.throbber::after {
      border-top-color: rgba(var(--glightbox--color--yellow), 0.25);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;

export const containerCls = css`
  /* Overlap the rest of the lightbox */
  position: absolute;
  top: 45px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

  /* Show throbber while contents are being loaded */
  &::before {
    /* Center the throbber */
    position: absolute;
    top: 50%;
    left: 50%;

    margin-left: -32px;
    margin-top: -32px;

    /* Apply sizing */
    display: block;
    box-sizing: border-box;
    width: 64px;
    height: 64px;

    /* Apply styling */
    border: 2px solid #ddd;
    border-top-color: #666;
    border-radius: 50%;

    /* Spin the throbber */
    animation: spin linear 900ms infinite;

    /* Force rendering of this pseudo-element */
    content: "";

    /* Prepare fade-out */
    transition: opacity linear 275ms, visibility linear 0ms 275ms;
  }

  /* Hide throbber when loaded */
  &.loaded::before {
    visibility: hidden;
    opacity: 0;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;

export const contentCls = css`
  /* Take up the entire contents-container */
  width: 100%;
  height: 100%;

  /* Apply white background by default */
  background: #fff;

  /* Hide until the contents are loaded */
  opacity: 0;
  transition: opacity linear 275ms;

  .${containerCls}.loaded & {
    opacity: 1;
  }

  &.type--image {
    background: #000;
  }

  &.type--message {
    background: #f5f5f5;
  }
`;

export const contentsMessageLabelCls = css`
  /* Center the message */
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  /* Styling of the message */
  font: 500 17px/25px var(--glightbox--font);
  color: #666;
  text-shadow: #fff 1px 1px 0;
  text-align: center;
`;

export const contentsImageCls = css`
  /* Center the image */
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  /* Setup max-sizes */
  max-width: 100%;
  max-height: 100%;
`;
