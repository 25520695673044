import axios from "axios";

import * as api from "../constants/api";

export async function fetchTaskResultById(
  taskResultId: string
): Promise<{
  taskPlayerID: string;
  productID: string;
  title: string;
  objectID: string;
}> {
  return (
    await axios.get(api.componentEntry.replace("%entryId%", taskResultId))
  ).data;
}
