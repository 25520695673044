import axios from "axios";

import * as api from "../constants/api";

export async function fetchComponentEntryById(
  componentEntryId: string
): Promise<{
  userID: string;
  objectID: string;
  componentID: string;
  componentType: string;
}> {
  return (
    await axios.get(api.componentEntry.replace("%entryId%", componentEntryId))
  ).data;
}
