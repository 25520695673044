import * as api from "../constants/api";
import { Options } from "../models/Options";

import { getUserData } from "./getUserData";
/**
 * Helper-method that opens the notebook associated with the currently
 * embedded contents in a new window.
 */
export function openNotebook(options: Options): void {
  const userData = getUserData();

  let url = api.notebook
    .replace("%productId%", options.productId ?? "")
    .replace("%userId%", userData.userId)
    .replace("%institutionId%", userData.instId ?? "");

  if (options.learningObject) {
    url +=
      "&context-learningObjectId=" +
      encodeURIComponent(options.learningObject.id);
    url +=
      "&context-learningObjectTitle=" +
      encodeURIComponent(options.learningObject.title);
    url +=
      "&context-learningObjectType=" +
      encodeURIComponent(options.learningObject.type);
  }

  url = window.gProxy.forwardTo(url, "notebook-3.0");

  // Open in a new window that's centered around the current window
  // eslint-disable-next-line security/detect-non-literal-fs-filename
  window.open(
    url,
    "notebook",
    "left=" +
      (window.screenLeft + 50) +
      ",top=" +
      (window.screenTop + 50) +
      ",width=" +
      (window.innerWidth - 100) +
      ",height=" +
      (window.innerHeight - 100)
  );
}
